<template>
  <header :class="{ 'headroom--unpinned': scrolled }">
    <div class="container">
      <div class="logo" @click="$router.push('/')"></div>
      <nav>
        <router-link to="/">Inicio</router-link>
        <a href="#faq">Preguntas Frecuentes</a>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      limitPosition: 2,
      scrolled: false,
      lastPosition: 0,
    };
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY;
      const isScrollingDown =
        this.lastPosition < currentScrollPosition && this.limitPosition < currentScrollPosition;
      const isAtTop = currentScrollPosition === 0;

      if (isScrollingDown) {
        this.scrolled = true;
      } else if (isAtTop) {
        this.scrolled = false;
      }

      this.lastPosition = currentScrollPosition;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
header {
  background: linear-gradient(to top, #003460, #005285);
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 500;
  transition: height 1.5s, background 1.5s;
}

header .logo {
  margin: 10px;
  width: 28vh;
  height: 5vh;
  background-image: url("../assets/logo1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

header .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header nav {
  display: none;
}

header a {
  padding: 5px 12px;
  text-decoration: none;
  font-weight: bold;
  color: white;
  font-size: 1.1rem;
}

header a:hover {
  color: rgb(206, 194, 194);
}

.headroom--unpinned {
  height: 70px; /* Evitar duplicación de la transición en background */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: height 0.5s, background 0.5s; /* Agregar solo las propiedades necesarias */
}

@media (min-width: 1300px) {
  header {
    width: 100%; /* Comentar la posición fija al utilizar flexbox */
  }

  header .container {
    flex-direction: row;
  }

  header nav {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
    gap: 20px;
  }
}
</style>
