<template>
  <div id="faq">
    <div class="container-faq">
      <h2>Preguntas Frecuentes</h2>
      <div class="col-12">
        <Accordion class="acordion">
          <AccordionTab v-for="(item, id) in items" :key="id" :header="item.titulo">
            <p class="text-accordion-p">{{ item.content }}</p>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="container-text-info">
        <img src="../assets/alert-circle.svg" alt="" />
        <p>
          Únicamente se incorporan a esta plataforma los bienes u objetos que se encuentren aptos
          para su devolución. Los datos personales consignados en el formulario de devolución, la
          información y/o documentos aportados para acreditar la titularidad sobre el bien tienen
          carácter de declaración jurada. Cualquier falsedad dará lugar a la cancelación del trámite
          y la formulación de denuncia penal ante la eventual comisión de delito de acción pública.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
export default {
  components: {
    Accordion,
    AccordionTab,
  },
  data() {
    return {
      items: [
        {
          titulo: "¿ Qué hago después de llenar el formulario ?",
          content:
            "Una vez descargado el formulario tienes que dirigirte a la dependencia Policial donde se encuentra el objeto para iniciar los trámites de devolución.",
        },
        {
          titulo: "¿Qué Documentación debo presentar ?",
          content:
            "Aquella que acredite la propiedad del objeto, como la factura, tu DNI, entre otros.",
        },
        {
          titulo: "¿Cuál es el plazo de entrega?",
          content:
            "Una vez presentado el formulario de solicitud con la documentación que acredite propiedad, Justicia autorizará la entrega del efecto, concluido esto, a la brevedad nos pondremos en contacto con vos, para que retires el objeto por la dependencia policial.",
        },
      ],
    };
  },
};
</script>

<style>
.container-text-info {
  margin-top: 25px;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 550;
}
.container-faq {
  width: 100%;
  min-height: 35vh;
  display: flex;
  padding: 10px 130px 50px 130px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.container-faq h2 {
  font-size: 2rem;
  color: #404040;
}

.acordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.text-accordion-p {
  font-size: 1rem;
  font-weight: bold;
}
.p-accordion .p-accordion-tab {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
@media (max-width: 624px) {
  .container-text-info {
    display: none;
  }

  .container-faq {
    padding: 5px 0 40px 0;
  }
}
</style>
