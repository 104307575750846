<template>
  <div class="hello">
    <Galleria
      :value="images"
      :responsiveOptions="responsiveOptions2"
      :numVisible="9"
      containerStyle="max-width: 50%"
      :circular="true"
      :fullScreen="true"
      :showItemNavigators="true"
      :visible.sync="displayBasic"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.itemImageSrc"
          :alt="slotProps.item.alt"
          style=" max-width: 100%; max-height: 100%; display: block"
        />
      </template>
      <!-- <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.thumbnailImageSrc"
          :alt="slotProps.item.alt"
          style="display: block"
        />
      </template> -->
    </Galleria>
  </div>
</template>

<script>
import Galleria from "primevue/galleria";
export default {
  methods: {
    show(images){
      this.images = images;
      this.displayBasic = true
    }
  },
  data: () => ({
    images: [],
    displayBasic: false,
    responsiveOptions2: [
      {
        breakpoint: "1500px",
        numVisible: 5,
      },
      {
        breakpoint: "1024px",
        numVisible: 3,
      },
      {
        breakpoint: "768px",
        numVisible: 2,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
      },
    ],
  }),
  components: { Galleria },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
