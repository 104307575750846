<template>
  <div>
    <div class="bg-no-repeat bg-cover bg-center hero relative">
      <div class="negro"></div>
      <div class="absolute container-hero">
        <div class="tittle">
          <h1>Bienvenidos</h1>
        </div>

        <span class="descripcion"> Programa de devolución de objetos</span>
        <div class="texto">
          <p>
            Localiza tu objeto robado a través de esta plataforma,<br />
            llena el formulario de solicitud y la Policía de San Juan<br />
            iniciará los trámites para su devolución.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hero {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/hero.png");
}
.container-hero {
  display: flex;
  top: 37%;
  left: 7%;
  flex-direction: column;
  text-align: left;
  gap: 25px;
}

.tittle h1 {
  color: #fff;
  font-size: 2.5em;
}

.descripcion {
  font: normal normal bold 4em/1.3em Roboto;
  color: #fff;
  text-shadow: 0px 3px 6px #0000009c;
  font-weight: bold;
}

.negro {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: black;
  opacity: 0.5;
}

.container-hero .texto {
  color: #ffffff;
  font: normal normal normal 1.8em/1.3em Roboto;
  margin-right: auto;
}
@media (max-width: 780px) {
  .hero {
    height: 48vh;
  }
  .container-hero {
    display: none;
  }
  .negro {
    height: 48vh;
  }
}
</style>
