<template>
  <div :class="{ close: !openDetail }">
    <div class="tarjeta4">
      <div class="imagenCard1">
        <!-- <img v-if="imgLoaded"
          :src="img[0].thumbnailImageSrc"
          class="imgbg"
          @error="imgError"
          @click="imageClick()"
        /> -->
        <div v-if="imgLoaded && img.length == 0" class="h-full">
          <div class="flex flex-column gap-2 justify-content-center h-full">
            <i class="pi pi-camera" style="color: #b3b8bf; font-size: 5rem"></i>
            <span style="font-size: 30px; color: #b3b8bf">Sin imagen disponble</span>
          </div>
        </div>
        <img
          v-if="imgLoaded && img.length > 0"
          :src="img[0].thumbnailImageSrc"
          class="imgbg"
          @error="imgError"
          @click="imageClick()"
        />
      </div>
      <div class="textoInformativo">
        <p class="text-3xl font-bold titulo-card-01">Objeto a recuperar</p>

        <h1 class="titulo-card-02">{{ doc.marca }} {{ doc.modelo }}</h1>
        <div class="flex gap-4">
          <span class="text-xl titulo-card-03">Marca: {{ doc.marca }} </span>
          <span class="text-xl titulo-card-03">Modelo:{{ doc.modelo }}</span>
        </div>
        <div class="flex flex-column gap-1 overflow-y-auto">
          <h2>Caracteristicas</h2>
          <span>{{ doc.descripcion }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import FormRecupero from "./FormRecupero.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    doc: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    imgLoaded: false,
    img: [],
    openDetail: false,
  }),
  async created() {},
  watch: {
    doc(v) {
      console.log("img?", v);
      this.loadImgs();
    },
  },
  methods: {
    async loadImgs() {
      try {
        if (!this.doc.EfectoDepositoID) return;
        console.log("doc", this.doc);
        let img = await this.$store.dispatch(
          "siis/getImagesForGallery",
          `/public/files/deposito/?search={"ArticuloID": [{ "operator": "=", "value": "${this.doc.EfectoDepositoID}"}]}`
        );
        this.img = img;
        // this.$store.dispatch("siis/agregarImagen", this.img);
        this.imgLoaded = true;
      } catch (e) {
        this.imgLoaded = true;
        console.log("err 2", e);
      }
    },
    showDetail(e) {
      // this.openDetail =  !this.openDetail
      this.$refs.op.toggle(e);
    },
    // imgError(e){
    //   console.log("imgError", e.target.src)
    //   e.target.src = this.img[0].itemImageSrc
    //   e.onerror = null
    // },
    imgError(e) {
      console.log("imgError", e.target.src);
      if (!Array.isArray(this.img)) return;
      if (e.target.src == this.img[0].thumbnailImageSrc2) return;
      this.imgOld = true;
      e.target.src = this.img[0].thumbnailImageSrc2;
    },
    // imageClick() {
    //   this.$viewerApi({
    //     images: this.img.map((v) => {
    //       return { src: v.itemImageSrc, "data-source": v.itemImageSrc };
    //     }),
    //   });
    // },
    imageClick() {
      let params;
      if (!this.imgOld) {
        params = {
          images: this.img.map((v) => {
            return { src: v.itemImageSrc, "data-source": v.itemImageSrc };
          }),
        };
      } else {
        params = {
          images: this.img.map((v) => {
            return { src: v.itemImageSrc2, "data-source": v.itemImageSrc2 };
          }),
        };
      }
      this.$viewerApi(params);
    },
  },
  components: {
    OverlayPanel,
    FormRecupero,
  },
};
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";
.close .card-body {
  @include styleclass("h-7rem");
}
.close .detail {
  display: none;
}
.cardRecuperado {
  background: rgb(51, 57, 120, 0.5);
}

.tarjeta4 {
  display: flex;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  flex-wrap: wrap;
  height: 350px;
  box-shadow: 8px 10px 15px -3px rgba(0, 0, 0, 0.1);
  //   box-shadow: 5px 8px 24px -2px rgba(0,0,0,0.75);
  // -webkit-box-shadow: 5px 8px 24px -2px rgba(0,0,0,0.75);
  // -moz-box-shadow: 5px 8px 24px -2px rgba(0,0,0,0.75);
}
.imagenCard1 {
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.imgbg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textoInformativo {
  width: 50%;
  height: 100%;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  gap: 18px;
}

@media (max-width: 650px) {
  .tarjeta4 {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .imagenCard1 {
    width: 100%;
    height: 50%;
    overflow: visible;
  }
  .textoInformativo {
    width: 100%;
    height: 50%;
    text-align: left !important;
    display: flex;
    flex-direction: column;
    padding: 4px 16px;
    gap: 5px;
  }
  .titulo-card-01 {
    display: none;
  }
  .titulo-card-02 {
    font-size: 1.5rem;
  }
  .titulo-card-03 {
    font-size: 1.1rem !important;
  }
}
</style>
