<template>
  <div style="background: #eff3f8" class="py-4 px-0 md:px-7 md:py-4">
    <div class="w-full min-h-screen flex gap-5" style="margin-top: 110px">
      <div class="w-full min-h-screen flex flex-column gap-3">
        <div class="col-12">
          <!-- <EfectoCard :doc="doc" /> -->
          <div>
            <Card :doc="doc" />
          </div>
        </div>

        <FormRecupero
          :titulo="'Complete el formulario de recuperacion'"
          :subTitulo="' 1. Complete el formulario de recuperacion'"
          :formRec="true"
          :doc="doc"
        />
        <!-- <FormRecupero :titulo="'Valide sus datos'" :validarDatos="true"/>
            <FormRecupero :titulo="'Descripcion del Objeto'" :subTitulo="'Describa aqui las caracteristicas que permitan identificar su objeto(si corespondey/o tuviere)'" :desObj="true"/>
            <FormRecupero :titulo="'Adjuntar Comprobantes'"  :adComprobantes="true"/> -->
      </div>
      <div class="col-12 md:col-4 contenedor-banner">
        <!-- <EfectoCard :doc="doc" /> -->
        <div class="banner">
          <img
            style="object-fit: cover; width: 100%; height: auto; border-radius: 20px"
            src="../assets/banner.jpg"
            alt=""
          />
        </div>

        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import FormRecupero from "@/components/FormRecupero.vue";
// import EfectoCard from '@/components/EfectoCard.vue'
import Card from "@/components/Card.vue";

import axios from "axios";

export default {
  components: {
    FormRecupero,
    // EfectoCard,
    Card,
  },
  data: () => ({
    doc: {},
  }),
  created() {
    window.scrollTo(0, 0);
  },
  async beforeCreate() {
    const {
      params: { idform },
    } = this.$route;
    let id = idform;

    let options = {
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/public/deposito/" + id,
    };
    let r = await axios.get(options.url, { withCredentials: true });
    console.log("resp get card", r);
    this.doc = r.data.doc;
  },
};
</script>

<style>
.banner {
  width: 60%;
  height: auto;
  overflow: hidden;
}
.contenedor-banner {
  display: flex;
  justify-content: center;
}
@media (max-width: 1220px) {
  .contenedor-banner {
    display: none;
  }
  /* .contenedor-formulario-card {
    padding: 1rem !important;
} */
}
</style>
