import Vue from "vue";
import VueRouter from "vue-router";
import DepositoPublic from "../views/Home.vue";
import EfectoPublic from "../views/Efectos-Public.vue";
import FormRecupero from "../views/Form-recupero";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: DepositoPublic,
  },
  { path: "/efectos-public", component: EfectoPublic },
  { path: "/:documento", component: EfectoPublic },
  { path: "/recuperar/formulario/:idform", component: FormRecupero },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
