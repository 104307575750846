import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Avatar from 'primevue/avatar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner'


export default  {
  load: (Vue) => {
    Vue.component('InputSwitch', InputSwitch);
    Vue.component('Button', Button);
    Vue.component('Badge', Badge);
    Vue.component('Dialog', Dialog);
    Vue.component('InputText', InputText);
    Vue.component('InputNumber', InputNumber);
    Vue.component('Avatar', Avatar);
    Vue.component('DataTable', DataTable);
    Vue.component('Column', Column);
    Vue.component('Card', Card);
    Vue.component('AutoComplete', AutoComplete);
    Vue.component('Dropdown', Dropdown);
    Vue.component('ProgressSpinner', ProgressSpinner);
  }
}