<template>
  <div class="Categorias w100">
    <div class="content-info-card">
      <h2>Buscá tu objeto en las siguientes categorías</h2>
    </div>
    <Carousel
      :value="menu"
      :numVisible="4"
      :numScroll="1"
      :responsiveOptions="responsiveOptions"
      :circular="true"
      :autoplayInterval="3000"
    >
      <template #item="slotProps">
        <div class="contenedor-card">
          <ButtonCat
            :img="slotProps.data.img"
            :title="slotProps.data.title"
            :description="slotProps.data.description"
            :url="slotProps.data.url"
          />
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script>
import ButtonCat from "./ButtonCat.vue";
import Carousel from "primevue/carousel";
export default {
  components: {
    ButtonCat,
    Carousel,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      menu: [
        {
          title: "Bicicletas",
          img: "/assets/bici_03.png",
          url: "/bicicletas",
          description:
            "Consulta tu bicicleta a través de la Marca, Modelo, número de serie, color, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales",
        },
        {
          title: "Celulares",
          img: "/assets/cel.png",
          url: "/celulares",
          description:
            "Consulta tu teléfono a través de la Marca, Modelo, número de serie y/o IMEI, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales.",
        },
        {
          title: "Electrodomesticos",
          img: "/assets/electro.png",
          url: "/electronica",
          description:
            "Consulta tu teléfono a través de la Marca, Modelo, número de serie y/o IMEI, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales.",
        },
        {
          title: "Audio y Video",
          img: "/assets/audio.jpeg",
          url: "/audioVideo",
          description:
            "Consulta tu teléfono a través de la Marca, Modelo, número de serie y/o IMEI, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales.",
        },
        {
          title: "Computacion",
          img: "/assets/pc.jpg",
          url: "/computacion",
          description:
            "Consulta tu teléfono a través de la Marca, Modelo, número de serie y/o IMEI, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales.",
        },
        {
          title: "Herramientas",
          img: "/assets/herramientas.jpg",
          url: "/herramientas",
          description:
            "Consulta tu teléfono a través de la Marca, Modelo, número de serie y/o IMEI, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales.",
        },
        {
          title: "Motos",
          img: "/assets/motos.jpeg",
          url: "/motos",
          description:
            "Consulta tu teléfono a través de la Marca, Modelo, número de serie y/o IMEI, realiza la identificación a través de las , y llena el formulario de solicitud para que la Policía de San Juan de inicio de las actuaciones legales.",
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1950px",
          numVisible: 4,
          numScroll: 4,
        },
        {
          breakpoint: "1400px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "1250px",
          numVisible: 2,
          numScroll: 2,
        },

        {
          breakpoint: "800px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "primeflex/primeflex.scss";

.bccol {
  @include styleclass("col-12 lg:col-8 md:col-6 sm:col-12");
}
.contenedor-card {
  display: flex;
  gap: 30px;
  height: 300px;
  padding: 15px 50px;
}
.Categorias {
  padding-top: 30px;
}

.content-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  margin: 10px;
}

.content-info-card h2 {
  font-size: 2.5rem;
  text-align: left;
}

.content-card-categorias {
  width: 100%;
  display: grid;
  padding: 30px 99px;
  gap: 45px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;
}

@media (max-width: 1400px) {
  .content-card-categorias {
    width: 100%;
    display: grid;
    padding: 30px 20px;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 300px;
  }
}

@media (max-width: 624px) {
  .text-info-card2 {
    display: none;
  }

  .content-info-card h2 {
    font-size: 2rem;
    text-align: center;
  }
  .contenedor-card {
    display: flex;
    gap: 30px;
    height: 300px;
    padding: 10px 5px;
  }
}
</style>
