<template>
  <div class="toolbar">
    <div>
      <Hero />
      <Info />
      <section id="acceso-rapido">
        <div class="container">
          <Categorias />
        </div>
      </section>

      <Faq />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Categorias from "../components/Categorias.vue";
import Hero from "../components/Hero.vue";
import Info from "../components/Info.vue";
import Faq from "../components/Faq.vue";

export default {
  name: "depositoList",
  props: {
    modal: {
      type: Boolean,
    },
  },
  components: {
    Categorias,
    Hero,
    Info,
    Faq,
  },
  async created() {
    // let request = await axios.get( `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/loginpublic/`, { withCredentials: true })
    // console.log(request)
  },
  data() {
    return {
      buscador: "",
      opcions: [
        { nombre: "CELULARES", value: "celulares" },
        { nombre: "ELECTRONICA", value: "electronica" },
        { nombre: "BICICLETAS", value: "bicicletas" },
      ],
    };
  },

  methods: {
    buscadorFunction() {
      // console.log("busca por : ", this.buscador)
      this.$router.push(`/${this.buscador}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
