import Vue from "vue";
import axios from "axios";
const state = () => ({
  list: [],
  load: false,
  filter: null,
  total: null,
});

const getters = {
  getTotal(state) {
    return state.total;
  },
  getList(state) {
    let list = state.list;
    if (state.filter) {
      list = list.filter((item) => {
        if (state.filter.MarcaID && item.MarcaID != state.filter.MarcaID) return false;
        if (state.filter.ModeloID && item.ModeloID != state.filter.ModeloID) return false;
        return true;
      });
    }
    return list;
  },
  getFiltros(state) {
    let marca = {};
    for (let i in state.list) {
      let item = state.list[i];
      if (!marca[item.MarcaID]) {
        marca[item.MarcaID] = { name: item.marca, modelo: {} };
      }
      if (!marca[item.MarcaID].modelo[item.ModeloID]) {
        marca[item.MarcaID].modelo[item.ModeloID] = {
          name: item.modelo,
        };
      }
    }

    let filtros = { marca: [] };
    for (let i in marca) {
      let moA = [];
      for (let y in marca[i].modelo) {
        moA.push({ id: y, name: marca[i].modelo[y].name });
      }
      filtros.marca.push({ id: i, name: marca[i].name, modelos: moA });
    }

    return filtros;
  },
};
const actions = {
  async getMarcas({ commit }, filter) {
    console.log("setFilters", filter);
    commit("setFilters", filter);
  },
  async setFilters({ commit }, filter) {
    console.log("setFilters", filter);
    commit("setFilters", filter);
  },
  async getList({ commit }, filter) {
    return new Promise(async (resolve, reject) => {
      let options = {
        url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/public/deposito/",
      };

      try {
        commit("setLoad", false);
        let r = await axios.get(options.url, { withCredentials: true, params: filter });
        console.log("celulares", r);
        // const r = await Vue.prototype.$siis.get(options)
        // console.log("get data", r.data.data)
        commit("setList", r.data.data);
        commit("setLoad", true);
        commit("setFilters", null);
        resolve();
      } catch (e) {
        console.log(e, e.response);
        commit("setList", []);
        commit("setLoad", true);
        reject();
      }

      // if (filter.offset == 0){
      //     let filtersTotal = {...filter, getTotal: true }
      //     delete filtersTotal['offset'];
      //     delete filtersTotal['limit'];

      //     let rTotal = await axios.get(options.url, { withCredentials: true, params: filtersTotal})
      //     console.log("rTotal", rTotal.data)
      //     commit('setTotal', parseInt(rTotal.data.doc.total))
      // }
    });
  },
};
const mutations = {
  setList(state, payload) {
    state.list = payload;
    return Promise.resolve();
  },
  setTotal(state, payload) {
    state.total = payload;
    return Promise.resolve();
  },
  setLoad(state, payload) {
    return new Promise((resolve) => {
      state.load = payload;
      resolve();
    });
  },
  setFilters(state, payload) {
    return new Promise((resolve) => {
      state.filter = payload;
      resolve();
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
