<template>
  <div class="EfectoList">
    <div class="preloader" v-if="!ready">
      <ProgressSpinner />
    </div>
    <div v-show="ready">
      <div class="header text-left">
        <div class="drop pt-3">
          <div class="titulo-cel pb-1">
            <h2>Seccion {{ type }}</h2>
          </div>
          <div class="titulo-cel pb-1">
            <h3>FILTRA LO QUE BUSCAS</h3>
          </div>
          <div class="flex flex-wrap gap-2">
            <Dropdown
              v-model="MarcaID"
              :options="filtros.marca"
              :filter="true"
              filterPlaceholder="Buscar una marca"
              placeholder="Todos"
              optionLabel="name"
              class="dropEstilo"
              style="width: 300px; margin-right: 0.5em"
            />
            <Dropdown
              v-if="MarcaID"
              v-model="ModeloID"
              :options="MarcaID.modelos"
              :filter="true"
              placeholder="Todos"
              optionLabel="name"
              optionValue="id"
              filterPlaceholder="Buscar un modelo"
              class="dropEstilo"
              style="width: 300px; margin-right: 0.5em"
            />
            <Button
              label="Filtrar"
              icon="pi pi-filter"
              class="p-button-secondary"
              @click="setFilters()"
            />
          </div>
        </div>
      </div>
      <ProgressSpinner
        v-if="activeSpinner"
        style="width: 80px; height: 80px; margin-right: 5em"
        strokeWidth="5"
        fill="#EEEEEE"
        animationDuration="1.5s"
      />
      <div class="body mt-4" v-else>
        <!-- <Message v-if="list.length == 0" severity="info">{{mensaje}}</Message> -->
        <!-- v-else -->
        <div class="pt-2 pb-2 text-lg text-left">{{ efectos.length }} objetos encontrados</div>
        <div class="grid flex align-items-stretch" v-if="!settingList">
          <div v-for="(item, index) in list" :key="index" class="col-12 md:col-6 lg:col-3">
            <EfectoCard :doc="item" @showGallery="showGallery" />
            <!--  -->
          </div>
        </div>
      </div>

      <div class="footer mb-2 bg-white" v-if="showPaginator">
        <Paginator
          class="w-100"
          :rows="12"
          :pageLinkSize="3"
          :totalRecords="efectos.length"
          @page="onPage"
        ></Paginator>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import Paginator from "primevue/paginator";
import EfectoCard from "@/components/EfectoCard.vue";
import EfectoGallery from "@/components/EfectoGallery.vue";
import Message from "primevue/message";

export default {
  props: {
    msg: String,
  },
  components: {
    EfectoCard,
    EfectoGallery,
    Paginator,
    Message,
  },
  data: () => ({
    type: "",
    ready: false,
    MarcaID: null,
    ModeloID: null,
    activeSpinner: false,
    filters: {},
    list: [],
    offset: 0,
    showPaginator: true,
    settingList: false,
  }),
  methods: {
    onPage(event) {
      window.scrollTo(0, 0);
      this.settingList = true;
      // console.log("event", event);
      this.offset = event.page * 12;

      let list = [];

      let last = this.offset + 12 > this.efectos.lenght ? this.efectos.lenght : this.offset + 12;
      let efectos = this.efectos;
      for (var i = this.offset; i < last; i++) {
        if (efectos[i]) list.push(efectos[i]);
      }

      this.list = list;
      setTimeout(() => {
        this.settingList = false;
      }, 200);
    },
    async get() {
      try {
        this.ready = false;
        // this.$store.dispatch("siis/setDependencias");

        // var idCategoria ;
        const {
          params: { documento },
        } = this.$route;
        this.type = documento;
        let query = {};
        let search = {};
        switch (this.type) {
          case "celulares":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_CEL },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_CEL },
            ];
            break;

          case "bicicletas":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_VEHICULO },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_BICI },
            ];
            break;

          case "electronica":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_CEL },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_ELECTRO },
            ];
            break;

          case "audioVideo":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_CEL },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_AUDIO },
            ];
            break;

          case "computacion":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_COMPUTACION },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_COMPUTACION },
            ];
            break;
          case "motos":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_MOTOS },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_MOTOS },
            ];
            break;
          case "herramientas":
            search["exp_pre_EfectoDeposito.CategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_CATEGORIA_HERRAMIENTAS },
            ];
            search["exp_pre_EfectoDeposito.SubcategoriaID"] = [
              { operator: "=", value: process.env.VUE_APP_SUBCATEGORIA_HERRAMIENTAS },
            ];
            break;
        }

        if (this.filters.MarcaID) {
          search["exp_pre_EfectoDeposito.MarcaID"] = this.filters.MarcaID;
        }

        if (this.filters.ModeloID) {
          search["exp_pre_EfectoDeposito.ModeloID"] = this.filters.ModeloID;
        }

        // query.offset = offset || 0,
        // query.limit = 12
        console.log("query", query);
        query.search = JSON.stringify(search);
        await this.$store.dispatch("deposito/getList", query);

        // console.log("aqui fin");
        this.ready = true;
        this.showPaginator = true;
        this.onPage({ page: 0 });
        // this.$store.dispatch("siis/setMarca");
        // this.$store.dispatch("siis/setModelo");
      } catch (e) {
        console.log("errr>>>", e);
      }
    },
    showGallery(images) {
      try {
        // console.log("showGallery",images)
        this.$refs.gallery.show(images);
      } catch (e) {
        console.error("showGallery", e);
      }
    },
    async setFilters() {
      this.activeSpinner = true;
      this.showPaginator = false;
      this.offset = 0;
      let filters = {};
      if (this.MarcaID) filters.MarcaID = this.MarcaID.id;
      if (this.ModeloID) filters.ModeloID = this.ModeloID;
      this.filters = filters;
      this.$store.dispatch("deposito/setFilters", filters);
      this.onPage({ page: 0 });
      // this.get(0)
      setTimeout(() => {
        this.showPaginator = true;
        this.activeSpinner = false;
      }, 1000);
    },
  },

  computed: {
    ...mapGetters({
      filtros: "deposito/getFiltros",
      efectos: "deposito/getList",
      total: "deposito/getTotal",
    }),
    ...mapState({
      // efectos: state => state.deposito.list,
    }),
    modelos: {
      get() {
        return []; //this.filtros.marca[this.MarcaID].modelos
      },
    },
  },

  async created() {
    window.scrollTo(0, 0);
    this.get();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
