<template>
  <div class="ButtonCat card2">
    <div
      class="imagen bg-cover bg-center bg-no-repeat text-white py-4 px-3"
      :style="'background-image: url(' + img + ')'"
    ></div>
    <div class="content-card2">
      <h3 style="font-size: 2rem" :class="{ tamaño: true }">{{ title }}</h3>
      <!-- <p>{{description}}</p> -->
      <Button class="boton-card2" label="consultar" @click="$router.push(url)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    url: String,
    img: String,
  },
  data() {
    return {
      fuente: false,
    };
  },
  created() {
    // if (this.title == "Electrodomesticos" || this.title == "Audio y Video" || this.title == "Computacion" || this.title == "herramientas") {
    //   this.fuente = true;
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.card2 {
  display: flex;
  width: 350px;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  box-shadow: 2px 8px 12px -4px rgba(0, 0, 0, 0.2);
}
.imagen {
  width: 100%;
  height: 100%;
}
.content-card2 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 20px;

  color: #002c42;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}
.boton-card2 {
  background: linear-gradient(to top, #003460, #005285) !important;
  width: 70%;
  border-radius: 18px !important;
  border: none !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  padding-top: 0.3rem !important;
}

@media (max-width: 1600px) {
  .card2 {
    width: 320px;
  }
  .tamaño {
    font-size: 2rem !important;
  }
}
@media (max-width: 1400px) {
  .card2 {
    width: 320px;
  }

  .tamaño {
    font-size: 2rem !important;
  }
}
@media (max-width: 1030px) {
  .card2 {
    width: 300px;
  }
}

@media (max-width: 960px) {
  .card2 {
    width: 270px;
  }
}

@media (max-width: 780px) {
  .tamaño {
    font-size: 1.8rem !important;
  }
  .card2 {
    width: 100%;
  }
}

@media (max-width: 460px) {
  .card2 {
    width: 100%;
  }
  .tamaño {
    font-size: 1.7rem !important;
  }
  .boton-card2 {
    font-size: 1rem !important;
  }
}

@media (max-width: 3500px) {
  .card2 {
    width: 100%;
  }
  .tamaño {
    font-size: 1.5rem !important;
  }
}
@media (min-width: 1800px) {
  .card2 {
    width: 325px;
  }
  .tamaño {
    font-size: 1.5rem !important;
  }
}
</style>
