<template>
  <footer>
    <div class="container">
      <div class="sanjuangob">
        <img src="/assets/police1.png" style="width: 120%" />
      </div>
      <div class="police-logo1">
        <img src="/assets/escudo1.png" alt="" />
        <div class="container__text">
          <p>Central de Policía</p>
          <p>Entre Ríos 579 - Capital</p>
          <p>Tel: 264-421-4050</p>
        </div>
      </div>

      <div class="redes-sociales">
        <span class="follow">Seguinos</span>
        <ul>
          <li>
            <a href="https://www.facebook.com/Policiadesanjuanok/" target="blank"
              ><i class="pi pi-facebook" style="color: white; font-size: 2rem"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/accounts/login/?next=/policiadesanjuan/"
              target="blank"
              ><i class="pi pi-instagram" style="color: white; font-size: 2rem"></i
            ></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCcNUwWAQYO8l96ebG6ezSLg" target="blank"
              ><i class="pi pi-youtube" style="color: white; font-size: 2rem"></i
            ></a>
          </li>
          <li>
            <a href="https://twitter.com/policia_sanjuan?lang=es" target="blank"
              ><i class="pi pi-twitter" style="color: white; font-size: 2rem"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="content_text">
      <span class="texto_footer_p"
        >Policía de la Provincia de San Juan © 2024 | Secretaría de Estado de Seguridad y Orden
        Público | Gobierno de la Provincia de San Juan Todos los derechos reservados. Powered by
        área Desarrollo D-8
      </span>
    </div>
  </footer>
</template>

<script>
import "primeicons/primeicons.css";
export default {};
</script>

<style lang="scss" scoped>
.redes-sociales ul li {
  display: inline-block;
  margin: 0 0.5rem;
  cursor: pointer;
}
.follow {
  text-align: center;
  display: block;
  padding: 0 0 5px 8px;
  color: white;
}

footer {
  background-color: #003665;
  background-image: url("../assets/back.png");
  mix-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 317px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 20px 0;
}
.police-logo1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
footer .container .police-logo1 img {
  height: 90px;
}
.container__text {
  color: white;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  justify-content: center;
  gap: 5px;
}

.content_text {
  width: 100%;
  height: 30%;
  color: white;
}

@media (min-width: 1200px) {
  footer {
    background-color: #003665;
    background-image: url("../assets/back.png");
    mix-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    height: 317px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  footer .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: 0 16vw 0 5vw;
    height: 70%;
  }
  footer .content_text {
    width: 100%;
    height: 30%;
    color: white;
  }
  footer .container .police-logo1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 114px;
  }
  .container__text {
    color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100%;
    justify-content: center;
    gap: 10px;
    margin-left: 10px;
  }

  .follow {
    text-align: left;
    display: block;
    padding: 0 0 5px 8px;
    color: white;
  }
}

@media (max-width: 560px) {
  footer .container .sanjuangob img {
    width: 100% !important;
  }
  .content_text {
    width: 100%;
    height: 30%;
    color: white;
    padding: 0px 10px 10px 10px;
    font-size: 0.6rem;
  }
}

@media (max-width: 390px) {
  .redes-sociales {
    margin-top: 10px;
  }
  .redes-sociales ul {
    display: flex;
    gap: 10px;
  }
  .follow {
    margin-bottom: 5px;
    font-size: 1rem;
  }
}
</style>
