<template>
  <div class="page px-4">
    <div class="flex justify-content-center">
      <div class="content" style="width: 100%">
        <Message v-if="mensajeSuccess" severity="success" @close="mensajeSuccess = false">{{
          mensaje
        }}</Message>
        <Message v-if="mensajeError" severity="error" @close="mensajeError = false">{{
          mensaje
        }}</Message>

        <div
          v-else
          class="formulario p-d-flex p-flex-wrap p-jc-start cards-container"
          style="margin-top: 118px"
        >
          <EfectoList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginator from "primevue/paginator";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import EfectoList from "@/components/EfectoList.vue";
import Message from "primevue/message";

import { mapState, mapActions } from "vuex";

// import importedParams from '../data/formularios_de_parametrizacion.js'

export default {
  components: { Dialog, Dropdown, Paginator, EfectoList, Message },
  name: "Efetos-public",
  data() {
    return {
      imagen:
        "https://celularpark.com.ar/tienda/protectores-de-pantalla/celulares-protectores-de-pantalla/protector-hydroglass-para-celulares/",
      pageActual: 0,
      dataDeposito: [],
      enviarIsDisabled: false,
      activeSpinner: false,
      errors: { dni: false, nombre: false, apellido: false },
      mensajeCampoObligatorio: "Este campo es obligatorio",
      mensaje: "",
      mensajeSuccess: false,
      mensajeError: false,
      idDeposito: "",
      idDependencia: "",
      nombre: "",
      apellido: "",
      dni: "",
      celular: "",
      numeroDenuncia: "",
      descripcion: "",
      loder: true,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      activeIndex: 0,
      selectedCity1: null,
      selectedCity2: null,
      index1: 0,
      parametros: [{ img: "" }],
      cities: [
        { name: "Samsumg", code: "NY" },
        { name: "Nokia", code: "RM" },
        { name: "Lg", code: "LDN" },
        { name: "Huawei", code: "IST" },
        { name: "Apple", code: "PRS" },
      ],
      cities1: [],
      celularesJson: [
        { name: "Samsung", code: "0b15a939-b931-11ec-8878-16c2a030bb97" },
        { name: "Nokia", code: "12f8e394-cc74-11ec-acbc-16c2a030bb97" },
        { name: "Xiaomi", code: "4d1a8aa3-cc74-11ec-acbc-16c2a030bb97" },
        { name: "BlackBerry", code: "5878da4b-cc74-11ec-acbc-16c2a030bb97" },
        { name: "Asus", code: "e9ca4514-cc76-11ec-acbc-16c2a030bb97" },
        { name: "HP", code: "ef8cbf6b-cc76-11ec-acbc-16c2a030bb97" },
        { name: "Pantech", code: "f8dc8dbf-cc76-11ec-acbc-16c2a030bb97" },
        { name: "Panasonic", code: "06344cd6-cc77-11ec-acbc-16c2a030bb97" },
        { name: "Cat", code: "4093c688-cc77-11ec-acbc-16c2a030bb97" },
        { name: "BGH", code: "390585d5-cc77-11ec-acbc-16c2a030bb97" },
        { name: "Tcl", code: "9609ac0f-cc78-11ec-acbc-16c2a030bb97" },
        { name: "Lenovo", code: "2e43c678-cc77-11ec-acbc-16c2a030bb97" },
        { name: "Apple", code: "28bb0dac-cc74-11ec-acbc-16c2a030bb97" },
        { name: "alcatel", code: "640d8c40-cc78-11ec-acbc-16c2a030bb97" },
        { name: "Sony", code: "1e2ef565-cc77-11ec-acbc-16c2a030bb97" },
        { name: "Motorola", code: "e8ab015f-aaab-11ec-816d-16c2a030bb97" },
      ],
      displayBasic: false,
      displayBasic1: false,
      displayCustom: false,
      textFilter: "",
      itemDescripcion: "",
    };
  },
  watch: {
    id_marca(v) {
      // this.cities = this.v.filter(item=> item.id_marca === this.selectedCity1 && item.id_tipo === "033a5479-b932-11ec-8878-16c2a030bb97");
      this.cities = v;

      // console.log(v)
    },
    //  id_modelo(v) {
    //   this.cities1 = v;
    // },
  },
  methods: {
    ...mapActions("routes", ["setRoute"]),
    async onPage(event) {
      // console.log(event)
      //si la pagina que estaba antes cambio, entonces actualizo la pagina actual
      if (this.pageActual != event.page) {
        this.loder = true;
        this.pageActual = event.page;
        let pagDeposito = this.dataDeposito.slice(event.first, event.first + event.rows);
        // console.log(this.dataDeposito.slice(4,2))
        // console.log(this.dataDeposito)
        // this.parametros =   await this.showImg(pagDeposito)
        this.loder = false;
      }
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
    },

    async getObjet() {
      this.loder = true;
      let res = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/deposito/`, {
        withCredentials: true,
        params: { efecto: "filtrar", marca: this.selectedCity1, modelo: this.selectedCity2 },
      });
      this.dataDeposito = res.data.data;
      // console.log(this.dataDeposito)

      if (this.dataDeposito.length > 0) {
        let pagDeposito = this.dataDeposito.slice(0, 6);
        // this.parametros =   await this.showImg(pagDeposito)
      } else {
        this.mensaje = "No se encontraron concidencias";
      }
      // if(res.length > 0)
      // {
      //   this.parametros = await this.showImg(this.dataDeposito)
      // }
      this.loder = false;
    },
    changeDropdown() {
      this.cities1 = this.id_modelo.filter((item) => item.id_marca === this.selectedCity1);

      //this.dropdown.id_modelo.options = this.id_modelo.filter(item=> item.id_marca === v);
      // console.log("valor droppp",this.dropdown.id_destino.options)
    },

    imageClick(index, item) {
      this.activeIndex = index;
      this.displayCustom = true;
      this.index1 = this.parametros.indexOf(item);
    },
    openBasic(descripcion) {
      this.displayBasic = true;
      this.itemDescripcion = descripcion;
    },
    closeBasic() {
      this.displayBasic = false;
    },

    async openBasic1(v) {
      // console.log(">>>>",v)
      this.idDependencia = v.DependenciaID;
      this.idDeposito = v.id_deposito;
      this.displayBasic1 = true;
    },
    closeBasic1() {
      this.displayBasic1 = false;
      this.setDatosForm();
    },
    async validacion() {
      console.log("apreto enviar");
      this.errors.dni = this.dni == "" ? true : false;
      this.errors.nombre = this.nombre == "" ? true : false;
      this.errors.apellido = this.apellido == "" ? true : false;
      if (this.verificacion()) {
        this.Formulario();
      }
    },
    verificacion() {
      for (const property in this.errors) {
        if (this.errors[property] == true) {
          console.log("entro");
          return false;
        }
      }
      console.log("paso\nthis.errores: ", JSON.stringify(this.errors));
      return true;
    },
    setDatosForm() {
      this.dni = "";
      this.nombre = "";
      this.apellido = "";
    },

    subir() {
      window.scrollTo(0, 0);
      console.log("leo");
    },

    async Formulario() {
      this.activeSpinner = true;
      this.enviarIsDisabled = true;
      try {
        let dataFormulario = {
          nombre: this.nombre,
          apellido: this.apellido,
          dni: this.dni,
          celular: this.celular,
          Numerodenuncia: this.numeroDenuncia,
          descripcion: this.descripcion,
          DependenciaID: this.idDependencia,
          id_deposito: this.idDeposito,
        };
        let request = await axios.post(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/formulario-deposito`,
          dataFormulario,
          { withCredentials: true }
        );
        if (request.data.error == "") {
          let buscandoDependencia = this.dependenciasList.find(
            (elemnt) => elemnt.id == this.idDependencia
          );
          // console.log("buscador: ",buscandoDependencia)
          if (buscandoDependencia) {
            let mensaje1 = buscandoDependencia.dependencia
              ? "La solicitud fue enviada con exito. Recuerde asistir con la documentacion que acredite la propiedad. \nEste articulo fue recuperado por: " +
                buscandoDependencia.dependencia
              : `El formulario se Guardo con exito.`;
            this.mensaje = buscandoDependencia.tel
              ? mensaje1 + " telfono: " + buscandoDependencia.tel
              : mensaje1;
            this.mensajeSuccess = true;

            this.setDatosForm();
            this.subir();
          } else {
            this.mensaje = `El formulario se Guardo con exito.`;
            this.mensajeSuccess = true;
            this.setDatosForm();
          }
          this.displayBasic1 = false;
          this.activeSpinner = false;
          this.enviarIsDisabled = false;
        }
      } catch (e) {
        this.mensaje = "Ocurrio un Error al Cargar el Formulario\n Error: " + e;
        this.mensajeError = true;
        this.displayBasic1 = false;
        this.activeSpinner = true;
        this.enviarIsDisabled = false;
      }
    },
  },
};
</script>
<style scoped>
.page {
  background: #ededed;
}
.content {
  max-width: 1215px;
  min-height: 90vh;
}
</style>
