<template>
  <div :class="{ close: !openDetail }">
    <div class="relative overflow-hidden bg-white border-round-sm pb-2">
      <div
        class="cardRecuperado absolute top-0 bottom-0 z-2 w-full flex align-items-center justify-content-center"
        v-if="doc.estadoEfecto == 1"
      >
        <!-- <div class="card_imagen">
          <div class="card_icon"></div>
          <div>
            <span>OBJETO DEVUELTO</span>
          </div>
        </div> -->
        <div>
          <img class="card__imgDevuelto" src="../assets/devuelto.png" />
        </div>
      </div>
      <div class="relative h-16rem overflow-hidden">
        <div v-if="imgLoaded && img.length == 0" class="h-full">
          <div class="flex flex-column gap-2 justify-content-center h-full">
            <i class="pi pi-camera" style="color: #b3b8bf; font-size: 5rem"></i>
            <span style="font-size: 25px; color: #b3b8bf">Sin imagen disponble</span>
          </div>
        </div>
        <img
          v-if="imgLoaded && img.length > 0"
          :src="img[0].thumbnailImageSrc"
          class="imgbg"
          @error="imgError"
          @click="imageClick()"
        />
      </div>
      <div class="card-body z-1 pt-2 px-3 flex align-content-between flex-wrap">
        <div class="card-title pt-3 w-full">
          <p class="text-left">
            <span class="textmarcas font-semibold">{{ doc.marca }}</span>
            <span class="textmarcas">{{ doc.modelo }}</span>
          </p>
        </div>
        <div v-if="doc.estadoEfecto == 1" class="pb-2 flex w-full justify-content-center">
          <Button label="Devuelto a sus dueños" class="p-button-outlined ml-2" />
        </div>
        <div class="content-footer pb-2 flex w-full justify-content-center" v-else>
          <!-- label="detalles" -->
          <Button
            label="Información"
            @click="showDetail"
            icon="pi pi-plus"
            class="p-button-outlined p-button-secondary"
          />
          <OverlayPanel ref="op" appendTo="body" style="max-width: 450px">
            <div class="py-3 line-height-3 text-left">{{ doc.descripcion }}</div>
          </OverlayPanel>
          <!-- class="p-button-secondary p-button-text font-semibold" -->

          <Button
            label="Recuperar"
            @click="$router.push(`recuperar/formulario/${doc.EfectoDepositoID}`)"
            class="p-button-outlined p-button-success ml-2"
          />
          <OverlayPanel ref="fr" appendTo="body" style="max-width: 450px">
            <FormRecupero :doc="doc" />
          </OverlayPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import FormRecupero from "./FormRecupero.vue";
export default {
  props: {
    doc: {
      type: Object,
      default: () => ({}),
    },
    // showGallery: Function,
  },
  data: () => ({
    imgLoaded: false,
    img: [],
    openDetail: false,
    imgOld: false,
  }),
  async created() {
    try {
      let img = await this.$store.dispatch(
        "siis/getImagesForGallery",
        `/public/files/deposito/?search={"ArticuloID": [{ "operator": "=", "value": "${this.doc.EfectoDepositoID}"}]}`
      );
      this.img = img;
      console.log("img", img);
      this.imgLoaded = true;
    } catch (e) {
      this.imgLoaded = true;
      console.log("err 2", e);
    }
  },
  methods: {
    showForm(e) {
      // this.openDetail =  !this.openDetail
      this.$refs.fr.toggle(e);
    },
    showDetail(e) {
      // this.openDetail =  !this.openDetail
      this.$refs.op.toggle(e);
    },
    imgError(e) {
      console.log("imgError", e.target.src);
      if (!Array.isArray(this.img)) return;
      if (e.target.src == this.img[0].thumbnailImageSrc2) return;
      this.imgOld = true;
      e.target.src = this.img[0].thumbnailImageSrc2;
    },
    imageClick() {
      let params;
      if (!this.imgOld) {
        params = {
          images: this.img.map((v) => {
            return { src: v.itemImageSrc, "data-source": v.itemImageSrc };
          }),
        };
      } else {
        params = {
          images: this.img.map((v) => {
            return { src: v.itemImageSrc2, "data-source": v.itemImageSrc2 };
          }),
        };
      }
      this.$viewerApi(params);
    },
  },
  components: {
    OverlayPanel,
    FormRecupero,
  },
};
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";
.close .card-body {
  @include styleclass("h-7rem");
}
.close .detail {
  display: none;
}
.close {
  min-width: 280px;
}
.cardRecuperado {
  background: rgb(51, 57, 120, 0.5);
}
.imgbg {
  min-width: 100%;
  max-width: 120%;
  max-height: 150%;
  min-height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  /* margin-top: -10%; */
  /* margin-top: 50%;
  transform: translateY(-50%); */
}
// .card_imagen{
//   width: 100%;
//   height: 60%;
//   background: red;
//   clip-path: polygon(0 0, 79% 0, 100% 100%, 0% 100%);
// }
// .card_icon{
//   width: 30%;
//   height: 100%;
//   background: blue;
// }
.card__imgDevuelto {
  width: 100%;
}
</style>
