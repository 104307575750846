<template>
  <div>
    <Card class="col-12 card4" v-if="exito">
      <template #title> {{ titulo }}</template>
      <template #subtitle> {{ subTitulo }} </template>
      <template #content>
        <ProgressSpinner
          v-if="activeSpinner"
          style="width: 80px; height: 80px; margin-rigth: 5em"
          strokeWidth="5"
          fill="#EEEEEE"
          animationDuration="1.5s"
        />
        <div class="p-fluid grid" v-else>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="datos.nombre"
                :class="[errors.nombre ? activeClass : '']"
              />
              <label for="nombre" :class="{ disable: errors.nombre }">Nombre</label>
              <label for="name" :class="{ disable: !errors.nombre }"
                >Nombre {{ mensajeCampoObligatorio }}</label
              >
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="datos.apellido"
                :class="[errors.apellido ? activeClass : '']"
              />
              <label for="apellido" :class="{ disable: errors.apellido }">Apellido</label>
              <label for="apellido" :class="{ disable: !errors.apellido }"
                >Apellido {{ mensajeCampoObligatorio }}</label
              >
            </span>
          </div>

          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <InputNumber
                id="dni"
                :class="[isActive || errors.dni ? activeClass : '']"
                mode="decimal"
                :useGrouping="false"
                v-model="datos.dni"
              />
              <label for="dni" :class="{ disable: errors.dni }"
                >D.N.I (solo numeros sin puntos)</label
              >
              <label for="dni" :class="{ disable: !errors.dni }"
                >D.N.I {{ mensajeCampoObligatorio }}</label
              >
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <InputNumber
                id="celular"
                :useGrouping="false"
                v-model="datos.celular"
                :class="[errors.celular ? activeClass : '']"
              />
              <!-- <InputText type="text" v-model="datos.celular" /> -->
              <label for="celular" :class="{ disable: errors.celular }">Celular</label>
              <label for="celular" :class="{ disable: !errors.celular }"
                >Celular {{ mensajeCampoObligatorio }}</label
              >
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <InputMask
                :class="[isActive || errors.idTramiteRenaper ? activeClass : '']"
                v-model="datos.idTramiteRenaper"
                mask="99999999999"
              />
              <label for="idTramiteRenaper" :class="{ disable: errors.idTramiteRenaper }"
                >Numero de Tramite (Ver D.N.I)</label
              >
              <label for="idTramiteRenaper" :class="{ disable: !errors.idTramiteRenaper }"
                >Numero de Tramite {{ mensajeCampoObligatorio }}</label
              >
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Dropdown
                id="sexo"
                v-model="datos.sexo"
                :options="[
                  { value: 'M', type: 'Masculino' },
                  { value: 'F', type: 'Femenino' },
                ]"
                optionValue="value"
                optionLabel="type"
                :class="[errors.sexo ? activeClass : '']"
              />
              <label for="sexo" :class="{ disable: errors.sexo }">Sexo</label>
              <label for="sexo" :class="{ disable: !errors.sexo }"
                >Sexo {{ mensajeCampoObligatorio }}</label
              >
            </span>
          </div>

          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="datos.numeroDenuncia"
                @blur="verificarEmail(datos.numeroDenuncia)"
                :class="[errors.numeroDenuncia ? activeClass : '']"
              />
              <label for="numDenuncia" :class="{ disable: errors.numeroDenuncia }">Email</label>
              <label for="numDenuncia" :class="{ disable: !errors.numeroDenuncia }"
                >Email {{ email ? "Ingrese un mail valido" : mensajeCampoObligatorio }}</label
              >
            </span>
          </div>
          <div class="field col-12 md:col-8">
            <span class="p-float-label">
              <Textarea
                v-model="datos.descripcion"
                rows="4"
                :class="[errors.descripcion ? activeClass : '']"
                :placeholder="
                  errors.descripcion
                    ? 'Describa aquí las características que permitan indentificar su objeto (si corresponde y/o tuviere) ' +
                      mensajeCampoObligatorio
                    : 'Describa aquí las características que permitan indentificar su objeto (si corresponde y/o tuviere)'
                "
              />
            </span>
          </div>

          <div style="width: 100%">
            <Button label="Enviar" @click="validacion()" class="enviarForm" />
          </div>
        </div>

        <!-- <div class="container-form-2">
          
          <FileUpload name="demo[]" url="./upload.php" @upload="onUpload" :multiple="true" :uploadLabel="'Subir Archivo'" :chooseLabel="'Elegir Archivo'" :cancelLabel="'Cancelar'" accept="image/*" :maxFileSize="1000000">
              <template #empty>
                  <p>Podes subir tu denuncia, comprobante de compra, y/o foto que demuestre que el objeto es tuyo,(formato jpg y pdf)</p>
              </template>
          </FileUpload>
                 
          
        </div> -->
      </template>
    </Card>

    <Card class="col-12" v-else>
      <template #content>
        <div class="containerRespuesta">
          <div class="flex justify-content-center align-items-center">
            <img style="width: 85px" class="imagen-logo-card-01" src="../assets/check.svg" alt="" />
            <p class="text-card-exito">{{ mensaje1 }}</p>
          </div>
          <img
            style="min-width: 300px; width: 40%; height: auto"
            src="../assets/logo1.png"
            alt=""
          />

          <div style="width: 100%">
            <Button
              icon="pi pi-file-pdf"
              label="Descargar Comprobante"
              @click="descargar()"
              class="p-button-raised p-button-secondary"
            />
          </div>

          <div ref="pdfimp" style="display: none">
            <div style="width: 8.25in; height: 8.75in">
              <img src="../assets/recuperos.jpg" alt="" class="pdfimagen" />

              <div style="width: 500px; margin-left: 20px">
                <h3 class="pdfimagen1">PROGRAMA RECUPERÁ TU OBJETO</h3>
                <p class="ppdfrec" style="width: 240px">recuperos.policiadesanjuan.gob.ar</p>
              </div>

              <div class="bodypdf">
                <p class="pdfimagen1">
                  Constancia de inicio de trámite para realizar el reconocimiento del objeto
                </p>
                <p>----Objeto Solicitado</p>
                <p>{{ doc.marca }} {{ doc.modelo }}</p>
                <div style="display: flex; gap: 80px">
                  <span>Marca: {{ doc.marca }}</span>
                  <span>Modelo: {{ doc.modelo }}</span>
                </div>

                <p>Características:</p>
                <span style="width: 500px"> {{ doc.descripcion }}</span>
              </div>

              <div class="bodypdf">
                <p class="pdfimagen1">Datos del solicitante</p>
                <span>Nombre y Apellido: {{ datos.nombre }} {{ datos.apellido }}</span>
                <span>D.N.I: {{ datos.dni }}</span>
                <span>Nro Celular: {{ datos.celular }}</span>
                <span>Nro de tramite: {{ ncomprobante }}</span>
              </div>

              <div class="bodypdf">
                <span style="width: 550px; font-weight: bold; text-align: left"
                  >Dirigirse con este comprobante a la comisaria {{ comisaria.Nombre }}
                  {{ comisaria.Domicilio ? "ubicada en " + comisaria.Domicilio : " " }} en horario
                  de 9:00 a 14:00hs 18:00 a 22:00hs con toda la documentación necesaria que acredite
                  que el objeto es de su propiedad, como lo son la factura de su compra, fotos del
                  objeto, o cualquier otro elemento que ayude con la identificación y
                  reconocimiento.</span
                >
              </div>

              <!-- <img style="width:200px" :src="imagenPDF[0].itemImageSrc2" alt=""> -->
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import InputMask from "primevue/inputmask";
import Card from "primevue/card";
import FileUpload from "primevue/fileupload";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import axios from "axios";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import jspdf from "jspdf";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    Card,
    FileUpload,
    Textarea,
    Dropdown,
    InputMask,
    Button,
    InputNumber,
    jspdf,
  },
  props: {
    msg: String,
    titulo: {
      type: String,
      default: "Fomulario",
    },
    subTitulo: {
      type: String,
      default: " ",
    },

    doc: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    datos: {
      nombre: "",
      apellido: "",
      dni: null,
      celular: null,
      numeroDenuncia: "",
      descripcion: "",
      id_deposito: "",
      idTramiteRenaper: null,
      sexo: "",
      dependenciaID: "",
      Email: "",
    },
    type: "",
    exito: true,
    activeSpinner: false,
    enviarIsDisabled: true,
    isActive: false,
    activeClass: "p-invalid",
    mensaje1: "",
    errors: {
      dni: false,
      nombre: false,
      apellido: false,
      idTramiteRenaper: false,
      sexo: false,
      numeroDenuncia: false,
      celular: false,
      descripcion: false,
    },
    mensajeCampoObligatorio: "(Campo obligatorio)",
    form: true,
    comisaria: "",
    ncomprobante: "",
    email: false,
  }),
  async created() {
    this.$store.dispatch("siis/setDependencias");
    const {
      params: { idform },
    } = this.$route;
    this.type = idform;
  },

  computed: {
    ...mapState({
      dependenciasList: (state) => state.siis.dependencia,
    }),
    // ...mapGetters({imagenPDF: 'siis/imagenPDF'})
  },

  methods: {
    verificarEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        return (this.errors.numeroDenuncia = false);
      } else {
        return (this.errors.numeroDenuncia = true), (this.email = true);
      }
    },
    descargar() {
      const doc = new jspdf("p", "pt", "a4");
      // console.log("leooooo", this.doc);
      const html = this.$refs.pdfimp.innerHTML;
      // console.log(htmll);
      doc.html(html, {
        callback: function (pdf) {
          pdf.save("comprobante.pdf");
        },
      });

      // console.log("en la foto",this.imagenPDF[0].itemImageSrc2)
    },
    async validacion() {
      this.errors.idTramiteRenaper =
        this.datos.idTramiteRenaper == null || this.datos.idTramiteRenaper == "" ? true : false;
      this.errors.dni = this.datos.dni == null ? true : false;
      this.errors.nombre = this.datos.nombre == "" ? true : false;
      this.errors.apellido = this.datos.apellido == "" ? true : false;
      this.errors.descripcion = this.datos.descripcion == "" ? true : false;
      this.errors.sexo = this.datos.sexo == "" ? true : false;
      this.errors.numeroDenuncia =
        this.datos.numeroDenuncia == "" || this.errors.numeroDenuncia == true ? true : false;
      this.errors.celular = this.datos.celular == null ? true : false;
      if (this.verificacion()) {
        this.enviar();
        console.log("listo para enviar");
      }
    },
    verificacion() {
      for (const property in this.errors) {
        console.log("aca", property);
        if (this.errors[property] == true) {
          window.scrollTo(0, 0);
          return false;
        }
      }
      console.log("paso\nthis.errores: ", JSON.stringify(this.errors));
      return true;
    },
    async enviar() {
      this.titulo = "Por favor Aguarde";
      this.subTitulo = "El Sistema esta validando sus datos";
      this.activeSpinner = true;
      this.datos.id_deposito = this.type;
      this.datos.Email = this.datos.numeroDenuncia;
      this.datos.dependenciaID = await this.dependenciasList.find(
        (elemnt) => elemnt.DependenciaID == this.doc.DependenciaID
      );
      this.datos.idTramiteRenaper = this.datos.idTramiteRenaper.toString().replace(/^(0+)/g, "");
      console.log("datos enviar>>>>", this.datos);
      try {
        let request = await axios.post(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/public/formulario/deposito`,
          this.datos
        );

        if (request.data.error == "") {
          this.ncomprobante = request.data.data.formulario_depositoID;
          let id1 = this.doc.DependenciaID;
          let buscandoDependencia = await this.dependenciasList.find(
            (elemnt) => elemnt.DependenciaID == id1
          );
          // console.log("mensaje",buscandoDependencia.Nombre)
          if (buscandoDependencia) {
            this.comisaria = buscandoDependencia;
            this.mensaje1 =
              "Este objeto se encuentra en: " +
              buscandoDependencia.Nombre +
              " Horario de atencion: 8:00 a 14:00 hs y de 18:00 a 22hs.";
          } else {
            this.mensaje1 =
              "Sus datos han sido validados correctamente la comisaria mas cercana se estara comunicando con ud a la brevedad";
          }

          console.log("leo");
          this.activeSpinner = false;
          this.exito = false;
        }
      } catch (e) {
        console.log(e);
        this.titulo = "Ocurrio un Error";
        this.subTitulo = " Por favor verifique su D.N.I y Numero de tramite sean correctos";
        this.activeSpinner = false;
        this.exito = true;

        this.datos.idTramiteRenaper = "";
        this.datos.dni = "";
        this.isActive = true;
      }

      //  if(request.data.error==''){
      //   console.log("se guardo")
      //  }else{
      //   console.log("nooo niñoooo")
      //  }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.containerRespuesta {
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 53px 30px 30px 53px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  box-shadow: 5px 8px 24px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 8px 24px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 8px 24px -2px rgba(0, 0, 0, 0.75);
}
.text-card-exito {
  text-align: left;
  color: #ffffff;
  line-height: 1.2;
  font-family: Roboto;
  font-size: 2rem;
}
.card4 {
  border-radius: 30px !important;
  box-shadow: 8px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
  /* box-shadow: 5px 8px 24px -2px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 5px 8px 24px -2px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 5px 8px 24px -2px rgba(0, 0, 0, 0.75) !important; */
}

button.p-button.p-component.enviarForm {
  width: 37%;
  border-radius: 22px;
  font-size: 22px;
}
.disable {
  display: none;
}

@media (max-width: 600px) {
  .p-card .p-card-body {
    padding: 0 1.5rem !important;
    padding-top: 1rem !important;
  }
  button.p-button.p-component.enviarForm {
    width: 70%;
    border-radius: 22px;
    font-size: 18px;
  }
  .imagen-logo-card-01 {
    display: none;
  }
  .containerRespuesta {
    padding: 30px;
  }
  .text-card-exito {
    font-size: 1.3rem;
  }
}
.pdfimagen {
  width: 70%;
  margin-left: 20px;
}
.pdfimagen1 {
  margin-top: 12px;

  text-align: left;
  /* font: normal normal 800 13px/15px Brokman;
  letter-spacing: 0px; */
  color: #707070;
  opacity: 1;
}
.ppdfrec {
  /* text-align: left; */
  /* font: normal normal normal 13px/14px Brokman;
  letter-spacing: 0px; */
  color: #707070;
  white-space: pre-line;
  /* opacity: 1; */
}
.bodypdf {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  margin-top: 20px;
  text-align: left;
  color: #707070;
  opacity: 1;
}
</style>
