
/*
TOAST OBJECT

Attribute 	Type 	Default 	Description
message 	String 	-- 	Message text/html (required)
type 	String 	success 	One of success, info, warning, error, default
position 	String 	bottom-right 	One of top, bottom, top-right, bottom-right,top-left, bottom-left
duration 	Number 	3000 	Visibility duration in milliseconds, set to 0 to keep toast visible
dismissible 	Boolean 	true 	Allow user dismiss by clicking
onClick 	Function 	-- 	Do something when user clicks
onDismiss 	Function 	-- 	Do something after toast gets dismissed
queue 	Boolean 	false 	Wait for existing to dismiss before showing new
pauseOnHover 	Boolean 	true 	Pause the timer when mouse on over a toast
*/
var VueCli
var axios
var store

const siis = {
     test: async () => {
      console.log("siis stiore>>>>", this)
      VueCli.$toast.open({message: "HI", position: 'top-right', type: 'error', duration: 3000, onClick: () => {
        alert("aa")
      }});
      try {

        let response = await axios.get('https://localhost:3000', { withCredentials: true }, {
          // headers: { ...options.headers }
        })
        console.log("response", response)
      }
      catch(e) {
        console.log("error", e)
      }
    },
    get: (options) => new Promise(async (resolve, reject) => {
      try {
        if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
        let params = options.params || {}
        let response = await axios.get(options.url, { withCredentials: true, params }, {
          headers: { ...options.headers }
        })
  
        let result = await siis.processResponse(response, options)
        return resolve(result);
  
      }
      catch (e) {
        // console.log("sale x aqui", e.response, e)
        return siis.processResponse(e.response, options)
        
      }
    }),
    delete: (options) => new Promise(async (resolve, reject) => {
      try {
        if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
  
        let response = await axios.delete(options.url, { withCredentials: true }, {
          headers: { ...options.headers }
        })
  
        let result = await siis.processResponse(response, options)
        return resolve(result);
  
      }
      catch (e) {
        // console.log("sale x aqui", e.response, e)
        return siis.processResponse(e.response, options)
        
      }
    }),
    post: (options) => new Promise(async (resolve, reject) => {
      try {
        if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
  
        let response = await axios.post(options.url, options.formData, { withCredentials: true }, {
          headers: { ...options.headers }
        })
  
        let result = await siis.processResponse(response, options)
        return resolve(result);
  
      }
      catch (e) {
        // console.log("sale x aqui", e.response, e)
        return siis.processResponse(e.response, options)
        
      }
    }),
    put: (options) => new Promise(async (resolve, reject) => {
      try {
        if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
  
        let response = await axios.put(options.url, options.formData, { withCredentials: true }, {
          headers: { ...options.headers }
        })
  
        let result = await siis.processResponse(response, options)
        return resolve(result);
  
      }
      catch (e) {
        // console.log("sale x aqui", e.response, e)
        return siis.processResponse(e.response, options)
        
      }
    }),
    processResponse: (response, options) => new Promise(async (resolve, reject) => {
      // console.log("processResponse", response)
        let successMsg = options.success || "La carga se realizo con exito."
  
        let errAlert = () => {
          let msg = typeof response.data == 'object' ? JSON.stringify(response.data) : response.data;
          alert(msg);
        }
        if (response.status == '401'){
          try {
            console.log("err 401>", response.data)
            if(response.data.error == 'Unauthorized'){
              VueCli.$toast.open({message: "La sesion ha caducado o no tiene permiso para la accion que desea realizar. Abriremos una nueva pestaña para que vuelva a loguearse. Luego de loguearse vuelva a intentarlo.", position: 'top-right', type: 'error', duration: 5000, onClick: errAlert});
              setTimeout(() => {
                window.open('/login?closeTab', '_blank')
              }, 3000)
              return reject(response);
            }

          } catch(e){

          }
          VueCli.$toast.open({message: "Ups! Algo fallo, te pedimos disculpas y te ayudaremos a resolverlo. Por favor, haz click en este cartel rojo, te aparecera un nuevo mensaje, envia una captura de pantalla con esa información a la mesa de ayuda tecnica.", position: 'top-right', type: 'error', duration: 0, onClick: errAlert});
          
         
        } else if ((response.status != '200') || (response.data && response.data.error != '')) {
          VueCli.$toast.open({message: "Ocurrio un error al cargar.", position: 'top-right', type: 'error', duration: 3000, onClick: errAlert});
          return reject(response)  
        } 
        if (options.success)VueCli.$toast.open({message: successMsg, position: 'top-right', type: 'success', duration: 3000});
        return resolve(response)
    }),
  }



module.exports = (Store, Vue, Axios) => {
  VueCli = Vue;
  axios = Axios;
  store = Store;
  return {
    post: siis.post,
    get: siis.get,
    delete: siis.delete,
    put: siis.put,
    test: siis.test,
  }

}

