import Vue from 'vue';
import Vuex from 'vuex';
import siis from './siis';
import deposito from './deposito';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    siis, deposito
  },
});
