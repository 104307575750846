import Vue from "vue";
import App from "./App.vue";
// import './registerServiceWorker';
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css";
import "/node_modules/primeflex/primeflex.css";
import "viewerjs/dist/viewer.css";
Vue.config.productionTip = false;
Vue.use(PrimeVue);
import VueViewer from "v-viewer";
Vue.use(VueViewer);
import import_components from "./import_components";
// import_components(Vue);
import_components.load(Vue);
import axios from "axios";

import SiisUtils from "./siis_utils";
Vue.prototype.$siis = SiisUtils(store, Vue, axios);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
