<template>
  <div>
    <section class="info">
      <div class="container-info">
        <div class="informacion">
          <h2>Programa de devolución de objetos</h2>
          <p>
            Localiza tu objeto a través de esta plataforma, identifica los que buscas, llena el
            formulario de solicitud y la Policía de San Juan iniciará los trámites para la
            devolución.
          </p>
        </div>
        <div class="informacion">
          <iframe
            src="https://www.youtube.com/embed/Z0ANsmu0KEU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="informacion">
          <h2>Recordá siempre hacer la denuncia</h2>
          <p>
            Si fuiste víctima de un delito es muy importante que realices la denuncia para que la
            Policía de San Juan pueda tomar una rápida intervención y avanzar en la investigación.
            Recordá que podés realizar la denuncia en cualquier comisaría los 365 días del año.
          </p>
        </div>
        <div class="informacion">
          <h2>Para tener en cuenta</h2>
          <p>
            Únicamente se incorporan a esta plataforma los bienes u objetos que se encuentren aptos
            para su devolución. Los datos personales consignados en el formulario de devolución, la
            información y/o documentos aportados para acreditar la titularidad sobre el bien tienen
            carácter de declaración jurada. Cualquier falsedad dará lugar a la cancelación del
            trámite y la formulación de denuncia penal ante la eventual comisión de delito de acción
            pública.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
.info {
  width: 100%;
  min-height: 100vh;
  padding: 50px 75px;
  background: linear-gradient(to top, #003460, #005285);
  display: flex;
  justify-content: center;
  align-items: center;
}

.info > div {
  color: #ffffff;
  text-align: left;

  /* font: normal normal bold 54px/72px Roboto; */
}
.info .container-info {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-evenly;
  position: relative;
  max-width: 100%;
}
.info .container-info .informacion {
  width: 30vw;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 20px;
}

.info .container-info .informacion h2 {
  font-size: 2.5rem;
}

.info .container-info .informacion p {
  font-size: 1.5rem;

  font-weight: 100;
}

iframe {
  width: 100%;
  height: 300px;
  border-radius: 15px;
}

.container-info img {
  position: absolute;
  top: 60px;
  width: 24%;
}

@media (max-width: 788px) {
  .info .container-info .informacion {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  iframe {
    width: 100%;
    height: 300px;
  }

  .info {
    padding: 50px 20px;
  }
  .info .container-info .informacion h2 {
    font-size: 1.8rem;
  }

  .info .container-info .informacion p {
    font-size: 1.2rem;

    font-weight: 100;
  }
}
</style>
